
import VerticalField from '@/components/shared/VerticalField.vue';
import { useFeatureStore } from '@/stores/FeatureStore';
import Vue from 'vue';

export default Vue.extend({
    name: 'FileUploadField',
    components: {
        VerticalField,
    },
    props: {
        name: { type: String, required: true },
        files: { type: Array<FileUpload>, required: false },
        featureFlag: { type: String, required: false },
        readonly: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        featureStore: useFeatureStore(),
    }),
    computed: {
        isFeatureEnabled(): boolean {
            return this.featureFlag ? this.featureStore.isEnabled(this.featureFlag) : true;
        },
    },
    methods: {
        async uploadFiles(event: Event) {
            const target = event.target as HTMLInputElement;
            const files = target.files as FileList;
            if (files.length === 0) return;

            const newFiles = [];
            for (var i = 0; i < files.length; i++) {
                const file = files[i];

                newFiles.push({ fileName: file.name, file });
            }
            this.$emit('changed', newFiles);
        },
        async deleteFile(id: number) {
            console.debug('Delete file id: ' + id);
        },
    },
});
